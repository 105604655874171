<template>
  <b-container class="graph-view">
    <b-row>
      <b-col>
        <h1>{{$t('Chart Report')}}
          <b-link :to="{ name: 'Reports' }">
            <b-icon-arrow-return-left/>
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items"/>
        <h2>Avg Score
          <b-link @click="show_modal = true">
            <b-icon-gear/>
          </b-link>
          <a></a>
        </h2>
      </b-col>
    </b-row>
    <b-modal hide-footer hide-header v-model="show_modal">
      <graph-form @ok="go_to"/>
    </b-modal>
    <b-overlay :show="loading">

      <b-row>
        <b-col>
          <line-chart :chartData="chard_data" height="500" :options="{
            maintainAspectRatio: false, ...chart_options
          }"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="header">
            <p>{{$t('Categories')}}: {{cat_text}}</p>
            <p>{{$t('Departaments')}}: {{dep_text}}</p>
            <p>{{$t('Lines')}}: {{line_text}}</p>
            <p>{{$t('Cells')}}: {{cell_text}}</p>
            <p>{{$t('Persons')}}: {{person_text}} </p>
            <p>{{$t('Operators')}}: {{operator_text}} </p>
          </div>
        </b-col>
      </b-row>
    </b-overlay>

  </b-container>

</template>

<script>
  import {dict_to_search, genColor, to_query} from "../shared";
  import LineChart from "../components/LineChart";
  import {uniq, sortBy, pick} from 'lodash-es'
  import GraphForm from "../components/GraphForm";

  export default {
    name: "Graph",
    components: {GraphForm, LineChart},
    mounted() {
      this.load()
    },
    data() {
      return {
        loading: false,
        show_modal: false,
        resp: []
      }
    },
    methods: {
      extract_date(obj) {
        const fields_to_extract = {
          none: [],
          day: ['date__date'],
          week: ['date__year', 'date__week'],
          month: ['date__year', 'date__month'],
          quarter: ['date__year', 'date__quarter']
        }
        const to_text = {
          none: obj => obj.category,
          day: obj => obj.date__date,
          week: obj => `${obj.date__year} W${obj.date__week}`,
          month: obj => `${obj.date__year} M${obj.date__month}`,
          quarter: obj => `${obj.date__year} Q${obj.date__quarter}`,

        }
        return {
          date: Object.values(pick(obj, fields_to_extract[this.group_by])).join('-'),
          date_text: to_text[this.group_by](obj)
        }
      },
      go_to(args) {
        this.show_modal = false
        this.$router.push({name: this.$route.name, query: to_query(args)})
        this.load()
      },
      build_datasets() {
        let ret = []
        const dates = uniq(sortBy(this.resp.map(r => r.date)))
        const categorys = uniq(sortBy(this.resp.map(r => r.category)))
        const cat_names = this.$store.getters.category.reduce(
          (r, c) => ({...r, [c.pk]: c.name}), {}
        )
        const colors_paleetre = categorys.reduce(
          (r, c) => ({...r, [c]: genColor()}),
          {}
        )
        for (const cat of categorys) {
          let data = []
          let colors = []
          for (const str_date of dates) {
            const d = this.resp.filter(d => d.date == str_date && cat == d.category).map(
              p => parseInt(p.avg_pscore * 100)
            )
            data = data.concat(d.length == 1 ? d : [0])
            colors = colors.concat(colors_paleetre[cat])
          }
          ret = ret.concat([{
            label: cat_names[cat],
            data: data,
            backgroundColor: colors
          }])
        }

        return ret
      },
      load() {
        let req = new URLSearchParams(dict_to_search(this.$route.query));
        this.loading = true
        Promise.all([
          this.$store.dispatch("load_data", ['category', 'additional_info']),
          this.$store.dispatch(
            'get', {
              url: `api/reports/graph/?${req}`
            }
          ).then(r => this.resp = r.map(r => ({...r, ...this.extract_date(r)})))
        ]).then(() => this.loading = false)
      },
    },
    computed: {
      chart_options() {
        return {
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const label = data.datasets[tooltipItem.datasetIndex].label;
                const value = tooltipItem.yLabel;
                return `${label}: ${value}%`
              }
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 20,
                  callback: value => `${value}%`
                }
              }
            ]
          }
        }
      },
      group_by() {
        return this.$route.query.group_by || 'day'
      },
      cat_text() {
        let ret = this.$t('All')
        if ((this.$route.query.category || []).length != 0) {
          ret = this.$store.getters.category.filter(
            c => this.$route.query.category.indexOf(c.pk) > -1
          ).map(c => c._name).join(', ')

        }
        return ret
      },
      dep_text() {
        let ret = this.$t('All')
        if ((this.$route.query.departament || []).length != 0) {
          ret = this.$store.getters.departament.filter(
            c => this.$route.query.departament.indexOf(c.pk) > -1
          ).map(c => c._name).join(', ')

        }
        return ret
      },
      line_text() {
        let ret = this.$t('All')
        if ((this.$route.query.info || []).length != 0) {
          ret = this.$store.getters.line.filter(
            c => this.$route.query.info.indexOf(c.pk) > -1
          ).map(c => c._name_with_dep).join(', ') || this.$t('All')

        }
        return ret
      },
      person_text() {
        let ret = this.$t('All')
        if ((this.$route.query.info || []).length != 0) {
          ret = this.$store.getters.person.filter(
            c => this.$route.query.info.indexOf(c.pk) > -1
          ).map(c => c._name).join(', ') || this.$t('All')

        }
        return ret
      },
      cell_text() {
        let ret = this.$t('All')
        if ((this.$route.query.info || []).length != 0) {
          ret = this.$store.getters.cell.filter(
            c => this.$route.query.info.indexOf(c.pk) > -1
          ).map(c => c._name_with_dep).join(', ') || this.$t('All')

        }
        return ret
      },
      operator_text() {
        let ret = this.$t('All')
        if ((this.$route.query.info || []).length != 0) {
          ret = this.$store.getters.operator.filter(
            c => this.$route.query.info.indexOf(c.pk) > -1
          ).map(c => c._name).join(', ') || this.$t('All')

        }
        return ret
      },
      chard_data() {
        const labels = this.group_by == 'none' ? ['Quizes'] : uniq(sortBy(this.resp.map(r => r.date_text)))
        return {
          labels: labels,
          datasets: this.build_datasets(),

        }
      },
      br_items() {
        return [
          {text: this.$t('Home'), to: {name: 'Home'}},
          {text: this.$t('Reports'), to: {name: 'Reports'}},
          {text: this.$t('Chart Report'), active: true}
        ]
      }
    }
  }
</script>

<style lang="scss">
  .graph-view {
    .header {
      p {
        margin: 0;
      }
    }
  }

</style>
